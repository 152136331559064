<template>
  <Confirm
    :dialog="dialog"
    :loading="loading"
    :width="'480px'"
    cancelText="Close"
    confirm-text="Save"
    max-width="90%"
    title="Note"
    @cancel="onCancel"
    @confirm="onConfirm"
    @toggleDialog="onCancel"
  >
    <v-container class="no-padding-top">
      <v-row>
        <v-col cols="12">
          <Textarea :height="67" :hideDetails="true" :value="note" placeholder="Note" @change="onChangeNote" />
        </v-col>
      </v-row>
    </v-container>
  </Confirm>
</template>

<script>
import { TaskApi } from "@/api/task";
import Confirm from "@/components/shared/Confirm.vue";
import Textarea from "@/components/uikit/Textarea.vue";

export default {
  name: "Note",
  components: { Textarea, Confirm },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    note: "",
    loading: false,
  }),
  methods: {
    onChangeNote(e) {
      this.note = e;
    },
    async onConfirm() {
      this.loading = true;
      if (this.note.length) {
        try {
          await TaskApi.addNote(this.taskId, this.note);
          this.note = "";
          this.$emit("toggleDialog");
        } catch (e) {
          console.error(e);
        }
      }
      this.loading = false;
    },
    onCancel() {
      this.note = "";
      this.$emit("toggleDialog");
    },
  },
};
</script>

<template>
  <div class="MedicalRequests">
    <v-row class="head mt-2">
      <v-col class="d-flex justify-space-between" cols="12">
        <h1>Clinical requests backlog</h1>
        <div class="d-flex gap-2">
          <v-btn :loading="isLoading" color="primary" icon title="Reload Requests" @click="reloadTasks">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
          <IconicButton :lite="true" :text="showFilter ? 'Filter' : 'Filter'" @onClick="updateShowFilter">
            <template v-slot:left>
              <v-switch :value="showFilter"></v-switch>
            </template>
            <template v-slot:right>
              <v-icon v-if="showFilter">mdi-chevron-down</v-icon>
              <v-icon v-else>mdi-chevron-up</v-icon>
            </template>
          </IconicButton>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showFilter" class="filter-wrap mt-2 added">
      <v-col cols="12" lg="3" md="4" sm="6">
        <p class="ma-0 mr-2">Date Range:</p>
        <RangeDatePicker
          v-model="authoredOn"
          :clearRangeDatePicker="false"
          :dense="true"
          :height="34"
          :hide-title="true"
          :hideDetails="true"
          :isYearFirst="false"
          className="date-picker__box-shadow v-input--hide-details--custom"
          @setDate="changeAuthoredOn"
        />
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <p class="ma-0 mr-2">Status:</p>
        <v-select
          :clearable="true"
          :items="statuses"
          :value="status"
          dense
          height="32px"
          hide-details="true"
          outlined
          placeholder="All"
          @change="changeFilterStatus($event)"
        ></v-select>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <p class="ma-0 mr-2">Doctors:</p>
        <v-select
          :clearable="practitionerId"
          :disabled="isLoading || isLoading"
          :items="practitionersList"
          :value="practitionerId"
          dense
          height="32px"
          hide-details="true"
          outlined
          placeholder="All"
          @change="changeSelectedPractitionerData($event)"
        ></v-select>
      </v-col>

      <v-col cols="12" lg="3" md="4" sm="6">
        <p class="ma-0 mr-2">Type of visit:</p>
        <div class="d-flex gap-2 mt-2">
          <Checkbox
            :checked="businessStatus.includes(TaskBusinessStatusEnum.pediatric)"
            text="Pediatric"
            @click="changeVisitType(TaskBusinessStatusEnum.pediatric)"
          />
          <Checkbox
            :checked="businessStatus.includes(TaskBusinessStatusEnum.adult)"
            text="Adult"
            @click="changeVisitType(TaskBusinessStatusEnum.adult)"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0" cols="12">
        <Pagination
          :page="page"
          :perPage="perPage"
          :total="total"
          @onFirst="onFirstPage"
          @onNext="onNextPage"
          @onPerPageChange="onPerPageChange"
          @onPrev="onPrevPage"
        />
      </v-col>
    </v-row>
    <v-row class="body">
      <v-col cols="12">
        <v-card v-for="task in items" :key="task.id" :class="task.status" class="task-item pa-4 mb-4" elevation="1">
          <div
            :style="{
              backgroundColor: translateStatus(task.status).color,
            }"
            class="status-label"
          >
            {{ translateStatus(task.status).text }}
          </div>

          <v-list-item class="requester-block">
            <v-list-item-avatar v-if="task?.requester?.profile">
              <Avatar :src="photoUrl(task)" :user-id="task?.requester?.profile?.id || null" />
            </v-list-item-avatar>
            <v-list-item-content v-if="task?.requester?.profile">
              <span class="heading-6">
                {{
                  (task.requester &&
                    `${task.requester.profile.firstName || ""} ${task.requester.profile.lastName || ""}`) ||
                  ""
                }}
              </span>
              <span
                v-if="task?.requester?.profile?.medicalRecordNumber"
                class="text--secondary d-flex align-center pointer"
              >
                {{ (task.requester && task.requester.profile && task.requester.profile.medicalRecordNumber) || "" }}
                <img
                  :src="require('@/assets/icons/mrn.svg')"
                  alt="medicalRecordNumber"
                  class="mrn"
                  title="Medical record number"
              /></span>
            </v-list-item-content>
          </v-list-item>
          <v-card-title class="task-title pb-1">
            <div>
              {{ title(task) }} <a :href="`tel:${task.phoneNumber}`" class="phone">{{ task.phoneNumber }}</a>
              <div class="status-date">Requested at &nbsp;{{ authoredOnDate(task) }}</div>
              <p v-if="task?.owner?.profile?.id" class="text-subtitle pb-0 mb-0">
                Taken by Dr. {{ task.owner.profile.firstName || "" }} {{ task.owner.profile.lastName || "" }}
              </p>
              <template v-if="task?.note?.length">
                <div v-for="(note, index) in task.note" :key="index" class="note">
                  <div class="value">{{ note.text }}</div>
                  <div class="time">{{ note.time }}</div>
                </div>
              </template>
            </div>
            <div class="task-contact-actions">
              <MeetNowMenu v-if="task?.requester?.profile" :recipient="task.requester.profile" />
              <CallNowAction :phone="task.phoneNumber" />
              <ChatNowAction
                v-if="task?.requester?.profile?.id"
                :phone-number="task.phoneNumber"
                :to-user-id="task.requester.profile.id"
              />
              <PrimaryButton class="past-btn" text="Add past visit" @onClick="gotoPastVisit">
                <template v-slot:icon-left>
                  <img :src="require('@/assets/history.svg')" alt="" />
                </template>
              </PrimaryButton>
            </div>
          </v-card-title>
          <v-card-actions>
            <div class="wrap-actions">
              <v-btn
                :class="{ active: ValueSetTaskStatus['in-progress'] === task.status }"
                color="primary"
                small
                @click="updateStatus(task, ValueSetTaskStatus['in-progress'])"
              >
                <v-icon>mdi-check-bold</v-icon>
                <span class="ml-2">Take</span>
              </v-btn>
              <v-btn color="success" small @click="updateStatus(task, ValueSetTaskStatus.completed)">
                <v-icon>mdi-check-all</v-icon>
                <span class="ml-2">Mark as complete </span>
              </v-btn>
              <v-btn color="error" small @click="updateStatus(task, ValueSetTaskStatus.failed)">
                <v-icon>mdi-alert-circle</v-icon>
                <span class="ml-2">No response </span>
              </v-btn>
              <v-btn outlined small @click="onEdit(task.id)">
                <v-icon>mdi-text</v-icon>
                <span class="ml-2">Quick Note</span>
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0" cols="12">
        <Pagination
          :page="page"
          :perPage="perPage"
          :total="total"
          @onFirst="onFirstPage"
          @onNext="onNextPage"
          @onPerPageChange="onPerPageChange"
          @onPrev="onPrevPage"
        />
      </v-col>
    </v-row>
    <Note v-if="noteTaskId" :dialog="noteDialog" :task-id="noteTaskId" @toggleDialog="noteDialog = !noteDialog" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { TaskBusinessStatusEnum, TaskOwnerEnum } from "@/api/task";
import Avatar from "@/components/Avatar/Index.vue";
import { timestampDisplayFormat } from "@/components/Clinicians/constants";
import ChatNowAction from "@/components/FloatingChat/ChatNowAction.vue";
import Pagination from "@/components/Pagination/View.vue";
import MeetNowMenu from "@/components/Patients/MeetNowMenu.vue";
import RangeDatePicker from "@/components/shared/RangeDatePicker.vue";
import Checkbox from "@/components/uikit/Checkbox.vue";
import IconicButton from "@/components/uikit/IconicButton.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import CallNowAction from "@/components/VoIP/CallNowAction.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { ValueSetTaskStatusEnum } from "@/pinia-store/interfaces/Task";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useTasksStore } from "@/pinia-store/tasks";
import { routesEnum } from "@/types/Routes.enum";
import Note from "@/views/Practitioner/MedicalRequests/Note.vue";

export default {
  name: "MedicalRequests",
  components: {
    Checkbox,
    RangeDatePicker,
    Pagination,
    IconicButton,
    PrimaryButton,
    ChatNowAction,
    Note,
    MeetNowMenu,
    CallNowAction,
    Avatar,
  },
  data() {
    return {
      authoredOn: [],
      pageTokens: {},
      isLoading: false,
      page: 1,
      perPage: 10,
      total: 0,
      nextPageToken: null,
      prevPageToken: null,
      showFilter: false,
      practitionerId: null,
      businessStatus: [TaskBusinessStatusEnum.pediatric, TaskBusinessStatusEnum.adult],
      filterStatus: [],
      status: null,
      statuses: [
        {
          value: null,
          text: "All",
        },
        { value: ValueSetTaskStatusEnum["draft"], text: "Not started" },
        { value: ValueSetTaskStatusEnum["in-progress"], text: "In progress" },
        {
          value: ValueSetTaskStatusEnum.completed,
          text: "Completed",
        },
        {
          value: ValueSetTaskStatusEnum.failed,
          text: "No response",
        },
      ],
      noteDialog: false,
      noteTaskId: null,
    };
  },
  computed: {
    TaskBusinessStatusEnum() {
      return TaskBusinessStatusEnum;
    },
    ...mapState(useTasksStore, ["items"]),
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapState(usePractitionersStore, ["practitioners"]),

    practitionersList() {
      const practitioners = this.practitioners?.map
        ? this.practitioners.map(({ firstName, lastName, id }) => ({
            text: `${firstName} ${lastName}`,
            value: id,
          }))
        : [];
      practitioners.unshift({
        text: "All",
        value: null,
      });
      return practitioners;
    },
    ValueSetTaskStatus() {
      return ValueSetTaskStatusEnum;
    },
  },
  methods: {
    ...mapActions(useTasksStore, ["getTasks", "updateTask"]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(usePractitionersStore, ["getAllPractitioners"]),
    async onFirstPage() {
      if (this.page === 1) {
        return;
      }
      this.page = 1;
      this.pageTokens = [];
      await this.fetchTasks();
    },
    async onNextPage() {
      // last page
      if (!this.pageTokens?.[this.page + 1]) {
        return;
      }
      this.page = this.page + 1;
      await this.fetchTasks(this.pageTokens[this.page]);
    },
    async onPrevPage() {
      if (this.page === 1) return;
      if (this.page === 2) {
        this.page = 1;
        await this.fetchTasks();
        return;
      }

      if (!this.pageTokens?.[this.page - 1]) return;
      this.page = this.page - 1;
      await this.fetchTasks(this.pageTokens?.[this.page - 1]);
    },
    async changeVisitType(type) {
      this.resetPagination();

      this.businessStatus.includes(type)
        ? (this.businessStatus = this.businessStatus.filter((t) => t !== type))
        : this.businessStatus.push(type);
      await this.fetchTasks();
    },
    async changeSelectedPractitionerData(selected) {
      this.practitionerId = selected;
      this.resetPagination();

      await this.fetchTasks();
    },
    async changeFilterStatus(selected) {
      this.status = selected;
      this.resetPagination();

      await this.fetchTasks();
    },
    async changeAuthoredOn(newDate) {
      this.authoredOn = newDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
      this.resetPagination();
      setTimeout(async () => {
        await this.fetchTasks();
      }, 200);
    },
    updateShowFilter() {
      this.showFilter = !this.showFilter;
      localStorage.setItem("tasksShowFilter", this.showFilter);
    },
    gotoPastVisit() {
      this.$router.push({ name: routesEnum.practitionerAddPastVisit });
    },
    photoUrl(task) {
      return task?.requester?.profile?.photoUrl;
    },
    onEdit(taskId) {
      this.noteTaskId = taskId;
      setTimeout(() => {
        this.noteDialog = true;
      }, 100);
    },
    translateStatus(status) {
      let resp = {
        text: "Not started",
        color: "#f2994a",
      };
      switch (status) {
        case ValueSetTaskStatusEnum["in-progress"]: {
          resp.text = "In progress";
          resp.color = "#2196f3";
          break;
        }

        case ValueSetTaskStatusEnum.completed: {
          resp.text = "Completed";
          resp.color = "#2fcf6f";
          break;
        }
        case ValueSetTaskStatusEnum.failed: {
          resp.text = "No response";
          resp.color = "#e53935";
          break;
        }
      }
      return resp;
    },

    authoredOnDate(task) {
      return this.$moment(task.authoredOn).format(timestampDisplayFormat);
    },
    title(task) {
      let consultationMethod = "Phone";
      if (task.valueCode === "phoneCall") consultationMethod = "Phone";
      else if (task.valueCode === "videoCall") consultationMethod = "Video";
      return `Patient requested a ${
        task.businessStatus === "pediatric" ? "Pediatric " : "Adult"
      } ${consultationMethod} Call consult. Phone Number: `;
    },
    async updateStatus(task, status) {
      this.startSave();
      await this.updateTask({ id: task.id, status });
      this.endSave();
    },
    resetPagination() {
      this.page = 1;
      this.pageTokens = {};
    },
    async onPerPageChange(perPage) {
      this.perPage = perPage;
      this.resetPagination();
      await this.fetchTasks();
    },
    async reloadTasks() {
      if (this.page === 1) {
        await this.fetchTasks();
        return;
      }
      await this.fetchTasks(this.pageTokens[this.page]);
    },
    async fetchTasks(pageToken = null) {
      if (typeof pageToken !== "string") {
        pageToken = null;
      }
      this.isLoading = true;
      let filters = {
        _count: this.perPage,
        authoredOn: this.authoredOn,
        ownerId: this.practitionerId,
        ownerRole: TaskOwnerEnum.practitioner,
        businessStatus: this.businessStatus,
        status: this.status,
      };
      if (pageToken) filters._page_token = pageToken;
      const data = await this.getTasks(filters);
      if (data?.pagination?.nextPageToken) {
        // pageTokens.1 = page token for page 1;
        this.pageTokens[this.page + 1] = data.pagination.nextPageToken;
      }
      this.total = data?.pagination?.total || 0;
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.showFilter = localStorage.getItem("tasksShowFilter") !== "false";
    await this.fetchTasks();
    await this.getAllPractitioners();
  },
};
</script>

<style lang="scss" scoped>
.MedicalRequests {
  padding: 0 30px 20px;
  @include tablet {
    padding: 0 15px 20px;
  }

  .head {
    align-content: center;

    h1 {
      align-self: center;
      @include tablet {
        font-size: 22px;
      }
      @include mobile {
        font-size: 16px;
      }
    }
  }

  :deep .past-btn {
    .v-btn__content {
      font-size: 12.15px;
      text-transform: uppercase;
      font-weight: 500;

      img {
        margin-right: 8px;
      }
    }
  }

  .filter-status {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  .task-item {
    border-radius: 5px;
    border: 1px solid $primarylight2;
    border-left: 3px solid #fff;

    .status-date {
      display: block;
      color: #999;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      width: 100%;
    }

    .task-title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #33343e;
      opacity: 0.9;
      display: flex;
      justify-content: space-between;
      @include tablet {
        flex-direction: column;
        gap: 10px;
      }

      .task-contact-actions {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 5px;
      }

      .phone {
        text-decoration: none;
        margin-left: 5px;
      }
    }

    .task-date {
      color: #999;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 5px;
    }

    &.draft {
      border-left-color: #f2994a;
    }

    &.in-progress {
      border-left-color: #2196f3;
    }

    &.completed {
      border-left-color: #2fcf6f;
    }

    &.failed {
      border-left-color: #e53935;
    }

    .status-label {
      font-size: 12px;
      font-weight: 500;
      color: white;
      position: absolute;
      top: 0;
      padding: 2px 10px 2px 7px;
      left: -1px;
      border-radius: 4px 0 13px 0 !important;
    }

    .requester-block {
      width: auto;
      min-height: inherit;

      .mrn {
        margin-left: 10px;
      }
    }

    .wrap-actions {
      width: 100%;
      padding-top: 10px;
      border-top: 1px solid #eeeff7;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      .v-btn__content {
        span {
          text-transform: none;
        }
      }
    }

    .note {
      .time {
        color: #999;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
</style>
